<script src="m20-upsell-details.component.ts"></script>
<div style=" position: relative; overflow: auto; height: 100%" class="y3-full-width" *ngIf="guide">
  <div class="m20-page-container" >

    <img *ngIf="currentPictureUrl == null" class="m20-image-container" src="{{utilsService.getGuideCover(guide, corporate)}}">
    <img *ngIf="currentPictureUrl != null" class="m20-image-container" src="{{currentPictureUrl}}">
    <div class="m20-return-container">
      <div class="y3-flex-row y3-flex-align-center y3-flex-justify-content-center" >
          <div class="m20-mat" (click)="back()">arrow_back</div>
      </div>
    </div>

    <div class="m20-card-container" >
      <div class="m20-card-content y3-grey-scroll" style="overflow-x: scroll; height: 60%">

        <div class="y3-bold y3-Large">{{guide.translations[0].title}}</div>
        <div class="y3-Small y3-color-scorpion y3-margin-top-8 m20-guide-description" [innerHTML]="guide.translations[0].description"></div>





        <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
          <!--<div class="y3-flex-row y3-flex-align-center ">
            <div class="y3-Medium y3-roboto ">{{'guest-guid-page.unit-price' | translate}} </div>
            <div class="y3-margin-left-8 y3-Medium y3-roboto y3-bold">{{guide.upSellSection.upSellAmount.amount | currency: guide.upSellSection.upSellAmount.currency?.toUpperCase():'symbol':'1.2-2':'en'}}</div>
          </div>-->

          <!--<div class="squares-container" *ngIf="guide.upSellSection.needQty">

            <div class="bulle-container">

              <div *ngIf="guide.upSellSection?.needQty" class="button-add-remove" (click)="removeQty(guide)">
                <div>-</div>
              </div>

              <div class="y3-Medium y3-flex-row y3-flex-justify-content-center" style="width: 30px;">
                <div>{{currentQty}}</div>
              </div>

              <div *ngIf="guide.upSellSection?.needQty" class="button-add-remove" (click)="addQty(guide)">
                <div>+</div>
              </div>


            </div>
          </div>-->

        </div>

        <!--<div class="y3-flex-row y3-flex-justify-content-end y3-margin-top-8 y3-flex-align-center">
          <div *ngIf="checkIfUpsellItemExists(guide.guideId)">
            <app-button [type]="'ternary'" [text]="'guest-guid-page.remove-from-card'" (debounceClick)="removeUpSellOnboarding(guide)"></app-button>
          </div>
          <div class="y3-margin-left-8">
            <app-button [type]="'primary-x-small'" [text]="'guest-guid-page.add-to-card'" (debounceClick)="addUpSellOnboarding(guide)"></app-button>
          </div>
        </div>-->


        <!--<div class="y3-bold y3-Large y3-margin-top-16">{{guide.translations[0].title}}</div>

        <div style="height: 150px;">
          <div class="y3-Small y3-color-scorpion y3-margin-top-8 m20-guide-description" [innerHTML]="guide.translations[0].description"></div>
        </div>
        -->

        <section id="SECTION____GALERIE____IMAGE" *ngIf="guide.documents != null && guide.documents?.length >= 1" class="y3-margin-top-8">
          <div class="y3-bold y3-Small">{{'m22-guide-display.more-pictures' | translate}}</div>
          <perfect-scrollbar class="y3-flex-row" [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
            <div class="y3-flex-row y3-flex-align-center y3-grey-scroll y3-margin-top-8 " style="overflow-x: hidden">
            <div *ngFor="let image of guide.documents" style="overflow-x: hidden !important">
                <img (click)="choosePicture(image.url)" class="m20-image-size y3-clickable" src="{{image.url}}" >
            </div>
            </div>
          </perfect-scrollbar>
        </section>

        <div class="y3-flex-column y3-margin-top-8" *ngIf="guide?.externalLinks && guide?.externalLinks?.length > 0">
          <div class="y3-bold y3-Small">{{'guest-guid-page.external-links' | translate}} </div>
          <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-margin-top-8" *ngFor="let link of guide.externalLinks; let i = index">
            <span class="roboto y3-bold y3-Small underline clickable width-90 txt-nowrap txt-ellipsis hidden-overflow" (click)="openLink(link.url)">{{link.title}}</span>
          </div>
        </div>

      </div>


      <div class="m20-card-call-to-action" *ngIf="guide.upSellSection.upSellAmount == null && guide.upSellSection.stripePaymentUrl != null ">
        <div class="y3-flex-row y3-flex-justify-content-end " *ngIf="guide?.upSellSection?.showAsUpSell && guide.upSellSection.stripePaymentUrl" >
          <app-button [type]="'primary-small'" [text]="'guest-guid-page.pay'" (debounceClick)="openLink(guide.upSellSection.stripePaymentUrl)"></app-button>
        </div>
      </div>




    </div>
  </div>

  <div style="position: absolute; bottom: 16px; width: 100%; padding-left: 24px; padding-right: 24px; background-color: white">

    <div *ngIf="guide?.upSellSection?.upSellToValidate" class="y3-margin-right-8 y3-margin-bottom-8 y3-full-width" style="width: fit-content; text-align: center ">
      <app-tag [padding]="'8px'" [sizeText2]="'12px'" [text]="'guest-guid-page.validation' | translate" [type]="'red'"></app-tag>
    </div>

    <div class="y3-Small y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-margin-bottom-8">



      <div class="y3-flex-row y3-flex-align-center " *ngIf="this.guide.upSellSection?.upSellAmount">
        <div class=" y3-roboto ">{{'guest-guid-page.unit-price' | translate}} </div>
        <div class="y3-margin-left-8  y3-roboto y3-bold">{{guide.upSellSection.upSellAmount.amount | currency: guide.upSellSection.upSellAmount.currency?.toUpperCase():'symbol':'1.2-2':'en'}}</div>
      </div>


      <div class="squares-container" *ngIf="guide.upSellSection.needQty">
        <div class="bulle-container">
          <div *ngIf="guide.upSellSection?.needQty" class="button-add-remove" (click)="removeQty(guide)">
            <div>-</div>
          </div>
          <div class="y3-Medium y3-flex-row y3-flex-justify-content-center" style="width: 30px;">
            <div>{{currentQty}}</div>
          </div>
          <div *ngIf="guide.upSellSection?.needQty" class="button-add-remove" (click)="addQty(guide)">
            <div>+</div>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="checkIfUpsellItemExists(guide.guideId)" class="y3-margin-bottom-8 y3-full-width y3-flex-row y3-flex-align-end y3-flex-justify-space-between">
      <div class="y3-X-small" >
        <div *ngIf="this.guide.upSellSection.needDate" class="y3-bold y3-margin-bottom-4">{{'guest-guid-page.delivery-date' | translate}}</div>
        <input *ngIf="this.guide.upSellSection.needDate" type="date" class="y3-input-text-small" [(ngModel)]="currentDate">
      </div>

      <app-button [type]="'ternary'" [text]="'guest-guid-page.remove-from-card'" (debounceClick)="removeUpSellOnboarding(guide)"></app-button>
    </div>


    <app-button *ngIf="this.guide.upSellSection?.upSellAmount" text="{{'guest-guid-page.order-for' | translate}} {{(this.guide.upSellSection?.upSellAmount?.amount * currentQty).toFixed(2)  | currency: this.guide.upSellSection.upSellAmount.currency.toUpperCase()}}" [type]="'new-primary'" (debounceClick)="addUpSellOnboarding(guide)"></app-button>
  </div>
</div>
