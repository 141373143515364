
<div style=" position: relative; overflow: auto; padding: 16px;" class="y3-grey-scroll" *ngIf="guide">

  <div class="guest-guide-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="closeWindow()" />
  </div>

  <!--<img src="assets/icon/close-round.svg" alt="icon" class="y3-close-button y3-clickable" (click)="closeWindow()">-->

  <!--DESKTOP NOT WIFI DETAILS-->
  <div  class="flex flex-column flex-space-between " *ngIf="!showingSlider && guide.category != null && guide.category !== 'wifi' && isDesktopMode()">

      <span class="mcqueen txt-color-black X-large margin-bottom-12 y3-title-colors" style="width: 80%"  >{{guide.translations[0]?.title}}</span>

    <div *ngIf="guide.textBeforePictures">
      <div  class="overflow-auto grey-scroll margin-top-16 ql-editor" style="height: auto; padding: 0px" *ngIf="guide?.translations?.length > 0"
            [innerHTML]="guide.translations[0].description"
      ></div>
      <app-audio-play *ngIf="!property.noAudioDisplay" [textToSpeak]="getCleanTextToRead(guide.translations[0].description)"></app-audio-play>
    </div>

      <div class="flex flex-column" >
        <div class="video-section margin-top-16 margin-bottom-24" *ngIf="guide?.videos && guide.videos.length > 0">
          <img src="/assets/icon/arrow-down.svg" *ngIf="guide.videos.length > 2" alt="previous" class="arrow clickable margin-right-16"
               (click) = "divScroll(videoContainer, 'left')">
          <div class="videos-container grey-scroll" #videoContainer>

            <div class="video-item" *ngFor="let video of guide.videos">
              <video controls *ngIf="video.type === DocType.VIDEO || video.type === DocType.EXTERNAL_VIDEO">
                <source src="{{video.url + '#t=0.001'}}" >
                Your browser does not support the video tag.
              </video>

              <app-external-video-box *ngIf="[DocType.YOUTUBE, DocType.VIMEO, DocType.DAILYMOTION].includes(video.type)"
                                      [videoUrl]="video.url"
              ></app-external-video-box>
            </div>

          </div>
          <img src="/assets/icon/arrow-down.svg" *ngIf="guide.videos.length > 2" alt="next" class="arrow clickable rotate-180 margin-left-16"
               (click) = "divScroll(videoContainer, 'right')">
        </div>



        <span *ngIf="guide?.documents && guide?.documents.length > 0" class="margin-bottom-24 margin-top-32 txt-nowrap mcqueen Large txt-color-black">
          {{'guest-guid-page.photos-documents' | translate}}
        </span>

        <div class="doc-section" *ngIf="guide?.documents && guide.documents.length > 0">
          <img src="assets/icon/arrow-down.svg"  *ngIf="guide.documents.length > 2" alt="previous" class="arrow clickable margin-right-16"
               (click) = "divScroll(docContainer, 'left')">
          <div class="doc-container grey-scroll" #docContainer>

            <div class="doc-item" *ngFor="let doc of guide.documents; let i= index" (click)="showSlider(i)">
              <img *ngIf="doc.type === DocType.IMAGE" src="{{doc.url}}"  class="image" alt="pic">
              <iframe *ngIf="doc.type === DocType.PDF"  class="grey-scroll"
                      [src]="doc.url | safe2:'resourceUrl'" ></iframe>

              <iframe *ngIf="doc.type === DocType.WORD" frameborder="0"
                      [src]="('https://view.officeapps.live.com/op/embed.aspx?src=' + doc.url) |safe2 : 'resourceUrl'"></iframe>
              <div class="element-mask"></div>
            </div>

          </div>
          <img src="assets/icon/arrow-down.svg" *ngIf="guide.documents.length > 2" alt="next" class="arrow clickable rotate-180 margin-left-16"
               (click) = "divScroll(docContainer, 'right')">
        </div>


        <div *ngIf="!guide.textBeforePictures">
          <div  class="overflow-auto grey-scroll margin-top-16 ql-editor" style="height: auto; padding: 0px" *ngIf="guide?.translations?.length > 0"
               [innerHTML]="guide.translations[0].description"
          ></div>
          <app-audio-play *ngIf="!property.noAudioDisplay" [textToSpeak]="getCleanTextToRead(guide.translations[0].description)"></app-audio-play>
        </div>


        <div class="flex flex-row flex-justify-end margin-top-16" *ngIf="guide?.poi && property.poi">
          <app-button [text]="'guest-guid-page.getting-there'" (debounceClick)="gettingThere()"></app-button>
        </div>

        <div class="flex flex-column margin-top-24" *ngIf="guide?.externalLinks && guide?.externalLinks?.length > 0">
          <h6>{{'guest-guid-page.external-links' | translate}} </h6>
          <div class="flex flex-row flex-align-center flex-space-between margin-top-8" *ngFor="let link of guide.externalLinks; let i = index">
            <span class="roboto bold Medium underline clickable width-90 txt-nowrap txt-ellipsis hidden-overflow" (click)="openLink(link.url)">{{link.title}}</span>
          </div>
        </div>

        <div class="flex flex-row flex-justify-end margin-top-16" *ngIf="guide?.upSellSection?.showAsUpSell && guide.upSellSection.stripePaymentUrl" >
          <app-button [type]="'primary'" [text]="'guest-guid-page.pay'" (debounceClick)="openLink(guide.upSellSection.stripePaymentUrl)"></app-button>
        </div>

        <section *ngIf="guide?.upSellSection?.upSellAmount != null">

          <div class="y3-flex-row y3-flex-justify-space-between y3-margin-top-16 y3-flex-align-center y3-Small">

            <div class="y3-flex-row y3-flex-align-center y3-Small">
              <div *ngIf="guide?.upSellSection?.upSellToValidate" class="y3-margin-right-8">
                <app-tag [padding]="'12px'" [sizeText2]="'12px'" [text]="'guest-guid-page.validation' | translate" [type]="'orange'"></app-tag>
              </div>
              <div *ngIf="guide?.upSellSection?.upSellAmount?.amount" class="y3-bold">
                <app-tag [padding]="'12px'" [sizeText2]="'12px'" text="{{guide?.upSellSection?.upSellAmount.amount}} {{guide?.upSellSection?.upSellAmount.currency}}" [type]="'blue'"></app-tag>
              </div>
            </div>


            <div>
              <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">

                <div class="squares-container" *ngIf="guide.upSellSection.needQty">

                  <div class="bulle-container">

                    <div *ngIf="guide.upSellSection?.needQty" class="button-add-remove" (click)="removeQty(guide)">
                      <div>-</div>
                    </div>

                    <div class="y3-Medium y3-flex-row y3-flex-justify-content-center" style="width: 30px;">
                      <div>{{currentQty}}</div>
                    </div>

                    <div *ngIf="guide.upSellSection?.needQty" class="button-add-remove" (click)="addQty(guide)">
                      <div>+</div>
                    </div>

                    <!--<div *ngIf="!checkIfUpsellItemExists(guide.guideId)" (click)="removeQty(guide)" class="clickable" >
                      <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">do_not_disturb_on</mat-icon>
                    </div>



                    <div class="y3-Medium y3-flex-row y3-flex-justify-content-center" style="width: 40px;">
                      <div>{{currentQty}}</div>
                    </div>
                    <div *ngIf="!checkIfUpsellItemExists(guide.guideId)" (click)="addQty(guide)" class="clickable" >
                      <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">add_circle</mat-icon>
                    </div>-->

                  </div>
                </div>

              </div>

              <div class="y3-flex-row y3-flex-justify-content-end y3-margin-top-8 y3-flex-align-center">
                <div *ngIf="checkIfUpsellItemExists(guide.guideId)">
                  <app-button [type]="'ternary'" [text]="'guest-guid-page.remove-from-card'" (debounceClick)="removeUpSellOnboarding(guide)"></app-button>
                </div>
                <div class="y3-margin-left-8">
                  <app-button [type]="'primary-x-small'" [text]="'guest-guid-page.add-to-card'" (debounceClick)="addUpSellOnboarding(guide)"></app-button>
                </div>
              </div>
            </div>

          </div>

        </section>

      </div>

      <!--<div class="overflow-auto grey-scroll padding-left-12" *ngIf="guide?.translations?.length > 0"
        [innerHTML]="guide.translations[0].description"
      ></div>-->

    </div>

  <!--DESKTOP WIFI DETAILS-->
  <div class="guide-container flex flex-row padding-32" *ngIf="!showingSlider && guide.category != null &&  guide.category === 'wifi' && isDesktopMode()">

    <!--<app-qr-code [isCopyVisible]="false" url="{{getWifiCode()}}"></app-qr-code>-->
    <!--<a href="{{getWifiUrl()}}">Wifi link</a>-->


    <div class="margin-left-24 flex flex-column">
      <span class="mcqueen Large txt-color-black margin-top-16 margin-bottom-16 y3-title-colors">
        {{'guest-guid-page.wifi' | translate}} : {{guide.translations[0].title}}
      </span>
      <span class="roboto Small txt-color-scorpion">
        {{'guest-guid-page.password' | translate}} : {{guide.translations[0].description}}
      </span>
      <!--<span class="roboto Small txt-color-scorpion margin-top-8">
        {{'guest-guid-page.security-type' | translate}} : {{guide.translations[0].wifiSecurityProtocol}}
      </span>-->
    </div>
  </div>

  <!--MOBILE NOT WIFI DETAILS-->
  <div class="width-100 flex flex-column " *ngIf="!showingSlider && guide.category != null && guide.category !== 'wifi' && !isDesktopMode()">

    <div *ngIf="guide.translations && guide.translations.length > 0" class="y3-High y3-roboto y3-bold y3-margin-bottom-16 y3-title-colors">{{guide.translations[0].title}}</div>

    <div class="video-mobile-section margin-top-16 margin-bottom-16" *ngIf="guide?.videos && guide.videos.length > 0">
      <img src="/assets/icon/arrow-down.svg"  alt="previous" class="arrow clickable margin-right-8"
           (click) = "divScroll(videoContainer, 'left')">
      <div class="videos-mobile-container grey-scroll" #videoContainer>

        <div class="video-mobile-item" *ngFor="let video of guide.videos">
          <video controls *ngIf="video.type === DocType.VIDEO || video.type === DocType.EXTERNAL_VIDEO">
            <source src="{{video.url + '#t=0.001'}}" >
            Your browser does not support the video tag.
          </video>

          <app-external-video-box *ngIf="[DocType.YOUTUBE, DocType.VIMEO, DocType.DAILYMOTION].includes(video.type)"
                                  [videoUrl]="video.url"
          ></app-external-video-box>
        </div>

      </div>
      <img src="/assets/icon/arrow-down.svg" alt="next" class="arrow clickable rotate-180 margin-left-8"
           (click) = "divScroll(videoContainer, 'right')">
    </div>

    <!--<h5 *ngIf="guide?.documents && guide?.documents.length > 0" class="y3-Small y3-roboto y3-margin-bottom-8 y3-margin-top-8 txt-nowrap ">
      {{'guest-guid-page.photos-documents' | translate}}
    </h5>-->

    <div class="flex flex-row flex-space-between flex-wrap" *ngIf="!mobileDocListOpened && guide.documents?.length > 4">
      <div class="doc-mobile-item" *ngFor="let i of [0,1,2,3]">
        <img *ngIf="guide.documents[i].type === DocType.IMAGE" src="{{guide.documents[i].url}}"  class="image" alt="pic">
        <iframe width="100%" height="100%" *ngIf="guide.documents[i].type === DocType.PDF"  class="grey-scroll"
                [src]="guide.documents[i].url | safe2:'resourceUrl'" ></iframe>

        <iframe *ngIf="guide.documents[i].type === DocType.WORD" frameborder="0"
                [src]="('https://view.officeapps.live.com/op/embed.aspx?src=' + guide.documents[i].url) |safe2 : 'resourceUrl'"></iframe>
        <div class="plus-counter-container mcqueen clickable" *ngIf="i === 3" (click)="mobileDocListOpened = true">
          {{'+' + (guide.documents.length - 4)}}
        </div>
        <div class="plus-counter-container no-bg clickable"  *ngIf="i < 3" (click)="showSlider(i)">
        </div>
      </div>
    </div>

    <div class="flex flex-row flex-space-between flex-wrap" *ngIf="mobileDocListOpened || guide.documents?.length <= 4">
      <div class="doc-mobile-item" *ngFor="let doc of guide.documents; let i = index">
        <img *ngIf="doc.type === DocType.IMAGE" src="{{doc.url}}"  class="image" alt="pic">
        <iframe *ngIf="doc.type === DocType.PDF"  class="grey-scroll"
                [src]="doc.url | safe2:'resourceUrl'" ></iframe>

        <iframe *ngIf="doc.type === DocType.WORD" frameborder="0"
                [src]="('https://view.officeapps.live.com/op/embed.aspx?src=' + doc.url) |safe2 : 'resourceUrl'"></iframe>
        <div class="plus-counter-container no-bg clickable" (click)="showSlider(i)">
      </div>
    </div>
    </div>

    <div class="width-100 overflow-auto grey-scroll y3-margin-top-8 y3-Small ql-editor" style="height: auto" *ngIf="guide?.translations?.length > 0"
         [innerHTML]="guide.translations[0].description"
    ></div>
    <app-audio-play *ngIf="!property.noAudioDisplay" [textToSpeak]="getCleanTextToRead(guide.translations[0].description)"></app-audio-play>

    <div class="flex flex-row flex-justify-end margin-top-16" *ngIf="guide?.poi">
      <app-button [text]="'guest-guid-page.getting-there'" (debounceClick)="gettingThere()"></app-button>
    </div>

      <div class="flex flex-column margin-top-24" *ngIf="guide?.externalLinks && guide?.externalLinks?.length > 0">
        <h6>{{'guest-guid-page.external-links' | translate}}</h6>

        <div class="flex flex-row flex-align-center flex-space-between margin-top-8 txt-nowrap txt-ellipsis hidden-overflow" *ngFor="let link of guide.externalLinks; let i = index">
          <span class="roboto bold Medium underline clickable  width-90 txt-ellipsis hidden-overflow" (click)="openLink(link.url)">{{link.title}}</span>
        </div>
      </div>

    <div class="flex flex-row flex-justify-end margin-top-16" *ngIf="guide?.upSellSection?.showAsUpSell && guide.upSellSection.stripePaymentUrl && guide.upSellSection?.upSellAmount == null">
      <app-button [type]="'primary'" [text]="'guest-guid-page.pay'" (debounceClick)="openLink(guide.upSellSection.stripePaymentUrl)"></app-button>
    </div>


    <section *ngIf="guide.upSellSection != null && guide?.upSellSection?.upSellAmount != null">

      <div class="y3-flex-column y3-flex-justify-space-between y3-margin-top-16 y3-Small">

        <div class="y3-flex-column y3-flex-align-start y3-flex-justify-content-start y3-Small y3-margin-bottom-16">
          <div *ngIf="guide?.upSellSection?.upSellToValidate" class="y3-margin-bottom-8">
            <app-tag [padding]="'12px'" [sizeText2]="'10px'" [text]="'guest-guid-page.validation' | translate" [type]="'orange'"></app-tag>
          </div>
          <div *ngIf="guide?.upSellSection?.upSellAmount?.amount" class="y3-bold">
            <app-tag [padding]="'12px'" [sizeText2]="'10px'" text="{{guide?.upSellSection.upSellAmount.amount}} {{guide?.upSellSection.upSellAmount.currency}}" [type]="'blue'"></app-tag>
          </div>
        </div>

        <div  *ngIf="currentUpSellOnBoarding == null">
          <div *ngIf="!checkIfUpsellItemExists(guide.guideId)">
            <app-button [type]="'primary-small'" [text]="'guest-guid-page.add-to-card'" (debounceClick)="addUpSellOnboarding(guide)"></app-button>
          </div>
          <div *ngIf="checkIfUpsellItemExists(guide.guideId)">
            <app-button [type]="'secondary-small'" [text]="'guest-guid-page.remove-from-card'" (debounceClick)="removeUpSellOnboarding(guide)"></app-button>
          </div>
        </div>

      </div>

    </section>

    <div *ngIf="!isDesktopMode()" class="empty-space"></div>


  <div class="flex flex-column flex-align-center" *ngIf="!showingSlider && guide.category != null &&  guide.category === 'wifi' && !isDesktopMode()">

    <!--<app-qr-code [isCopyVisible]="false" url="{{getWifiCode()}}"></app-qr-code>-->


    <div class="flex flex-column">
      <span class="mcqueen Large txt-color-black margin-top-16 margin-bottom-16 y3-title-colors">
        {{'guest-guid-page.wifi' | translate}} : {{guide?.translations[0]?.title}}
      </span>
      <span class="roboto Small txt-color-scorpion">
        {{'guest-guid-page.password' | translate}} : {{guide?.translations[0]?.description}}
      </span>
      <span class="roboto Small txt-color-scorpion margin-top-8">
        {{'guest-guid-page.security-type' | translate}} : {{guide?.translations[0]?.wifiSecurityProtocol}}
      </span>
    </div>
  </div>


  </div>

  <!--MOBILE WIFI DETAILS-->
  <div class="flex flex-column flex-align-center flex-justify-center padding-32" *ngIf="!showingSlider && guide.category != null &&  guide.category === 'wifi' && !isDesktopMode()">

    <!--<app-qr-code [isCopyVisible]="false" url="{{getWifiCode()}}"></app-qr-code>-->


    <div class="margin-left-24 flex flex-column">
    <span class="mcqueen Large txt-color-black margin-top-16 margin-bottom-16">
      {{'guest-guid-page.wifi' | translate}} : {{guide.translations[0].title}}
    </span>
      <span class="roboto Small txt-color-scorpion">
      {{'guest-guid-page.password' | translate}} : {{guide.translations[0].description}}
    </span>
      <span class="roboto Small txt-color-scorpion margin-top-8">
      {{'guest-guid-page.security-type' | translate}} : {{guide.translations[0].wifiSecurityProtocol}}
    </span>
    </div>
  </div>

  <!--SLIDER DESKTOP-->
  <div class="width-100 flex flex-column padding-32" *ngIf="showingSlider && isDesktopMode()">

    <div class="flex flex-row flex-align-center clickable" (click)="showingSlider = false">
      <img src="assets/icon/arrow-down.svg" alt="Icon" class="margin-right-8">
      <span class="roboto bold Small txt-color-grvogue">{{'guest-guid-page.return' | translate}}</span>
    </div>

    <div class="slider-container margin-top-16">
      <div class="slider-content" *ngIf="guide && guide.documents && guide.documents.length > 0">

        <img *ngIf="guide?.documents[sliderIndex].type === DocType.IMAGE" src="{{guide?.documents[sliderIndex].url}}"
             class="slider-picture" alt="">

        <iframe width="100%" height="100%" *ngIf="guide?.documents[sliderIndex].type === DocType.PDF"
                [src]="(guide?.documents[sliderIndex].url + '#toolbar=0') | safe2:'resourceUrl'"></iframe>

        <iframe width="100%" height="100%" *ngIf="guide?.documents[sliderIndex].type === DocType.WORD" frameborder="0"
                [src]="('https://view.officeapps.live.com/op/embed.aspx?src=' + guide?.documents[sliderIndex].url) |safe2 : 'resourceUrl'"></iframe>

        <button *ngIf="guide.documents.length > 1" appDebounceClick (debounceClick)="previous()" class="fab-button arrow-left" mat-mini-fab color="primary"
                aria-label="Previous">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
        <button *ngIf="guide.documents.length > 1" appDebounceClick (debounceClick)="next()" class="fab-button arrow-right" mat-mini-fab color="primary"
                aria-label="Next">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </div>

      <span class="roboto bold Small txt-color-grvogue margin-top-8">{{sliderIndex + 1}}
        /{{guide?.documents?.length}}
      </span>
      <span class="roboto legend-viewer txt-color-scorpion">
        {{guide?.documents[sliderIndex].description}}
      </span>
    </div>

  </div>

  <!--SLIDER MOBILE-->
  <div class="width-100 flex flex-column slider-mobile" *ngIf="showingSlider && !isDesktopMode()">

    <div class="flex flex-row flex-align-center clickable margin-top-24 padding-left-32" (click)="showingSlider = false">
      <img src="assets/icon/arrow-down.svg" alt="Icon" class="margin-right-8">
      <span class="roboto bold Small txt-color-grvogue">{{'guest-guid-page.return' | translate}}</span>
    </div>

    <div class="slider-container margin-top-16">
      <div class="slider-content" *ngIf="guide && guide.documents && guide.documents.length > 0">

        <img *ngIf="guide?.documents[sliderIndex].type === DocType.IMAGE" src="{{guide?.documents[sliderIndex].url}}"
             class="slider-picture" alt="">

        <iframe width="100%" height="100%"*ngIf="guide?.documents[sliderIndex].type === DocType.PDF"
                [src]="(guide?.documents[sliderIndex].url + '#toolbar=0#zoom=100') | safe2:'resourceUrl'"></iframe>

        <iframe width="100%" height="100%" *ngIf="guide?.documents[sliderIndex].type === DocType.WORD" frameborder="0"
                [src]="('https://view.officeapps.live.com/op/embed.aspx?src=' + guide?.documents[sliderIndex].url) |safe2 : 'resourceUrl'"></iframe>

        <button *ngIf="guide.documents.length > 1" appDebounceClick (debounceClick)="previous()" class="fab-button arrow-left" mat-mini-fab color="primary"
                aria-label="Previous">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
        <button *ngIf="guide.documents.length > 1" appDebounceClick (debounceClick)="next()" class="fab-button arrow-right" mat-mini-fab color="primary"
                aria-label="Next">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </div>

      <span class="roboto bold Small txt-color-grvogue margin-top-8">{{sliderIndex + 1}}
        /{{guide?.documents?.length}}
      </span>
      <span class="roboto legend-viewer txt-color-scorpion">
        {{guide?.documents[sliderIndex].description}}
      </span>
    </div>

  </div>

</div>
